import React, { useEffect } from 'react'
import Head from 'next/head'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Router from 'next/router'
import NProgress from 'nprogress'
import OfflineSupport from 'components/OfflineSupport'
import CustomHelmet from 'components/CustomHelmet'
import { usePageContext } from 'utils/usePageContext' // New hook for client-side context
import store from 'store'
import muiTheme from 'theme'
import 'mapbox-gl/dist/mapbox-gl.css'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function MyApp ({ Component, pageProps }) {
  // Client-side page context
  const pageContext = usePageContext()

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles?.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }, [])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1.0, maximum-scale=5, width=device-width, viewport-fit=cover"
        />
      </Head>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <OfflineSupport />
        <Provider store={store}>
          <CustomHelmet />
          <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <Container maxWidth="xs" style={{ padding: '0px' }}>
              <Component pageContext={pageContext} {...pageProps} />
            </Container>
          </ThemeProvider>
        </Provider>
        <Box mb={10} />
      </MuiPickersUtilsProvider>
    </>
  )
}

// Remove withRedux HOC and use regular Provider (already in the component)
export default MyApp
