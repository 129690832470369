// utils/usePageContext.js
import React from 'react'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/styles'

export function usePageContext () {
  const [jss, setJss] = React.useState(null)
  const [sheetsManager] = React.useState(new Map())

  React.useEffect(() => {
    const newJss = create({
      ...jssPreset(),
      insertionPoint: document.getElementById('jss-insertion-point') || undefined
    })
    setJss(newJss)
  }, [])

  return { jss, sheetsManager }
}

export function PageContextProvider ({ children }) {
  const pageContext = usePageContext()
  return (
    <StylesProvider jss={pageContext.jss} sheetsManager={pageContext.sheetsManager}>
      {children}
    </StylesProvider>
  )
}
